import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixTabs from '@1024pix/pix-ui/components/pix-tabs';
import { action } from '@ember/object';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import { or } from 'ember-truth-helpers';

export default class CampaignTabs extends Component {
  @service intl;
  @service notifications;
  @service fileSaver;
  @service session;
  @service metrics;

  @action
  async exportData() {
    try {
      const token = this.session.data.authenticated.access_token;
      await this.fileSaver.save({ url: this.args.campaign.urlToResult, token });
      this.metrics.add({
        event: 'custom-event',
        'pix-event-category': 'Campagnes',
        'pix-event-action': "Cliquer sur le bouton d'export des résultats d'une campagne",
        'pix-event-name': "Clic sur le bouton d'export",
      });
    } catch {
      this.notifications.sendError(this.intl.t('api-error-messages.global'));
    }
  }

  <template>
    <div class="campaign-header-tabs">
      <PixTabs @variant="orga" @ariaLabel={{t "navigation.campaign-page.aria-label"}}>
        <LinkTo @route="authenticated.campaigns.campaign.activity" @model={{@campaign}}>
          {{t "pages.campaign.tab.activity"}}
        </LinkTo>

        <LinkTo
          @route={{if
            (or @campaign.isTypeAssessment @campaign.isTypeExam)
            "authenticated.campaigns.campaign.assessment-results"
            "authenticated.campaigns.campaign.profile-results"
          }}
          @model={{@campaign}}
        >
          {{t "pages.campaign.tab.results" count=@campaign.sharedParticipationsCount}}
        </LinkTo>

        {{#if (or @campaign.isTypeAssessment @campaign.isTypeExam)}}
          <LinkTo @route="authenticated.campaigns.campaign.analysis" @model={{@campaign}}>
            {{t "pages.campaign.tab.review"}}
          </LinkTo>
        {{/if}}

        <LinkTo @route="authenticated.campaigns.campaign.settings" @model={{@campaign}}>
          {{t "pages.campaign.tab.settings"}}
        </LinkTo>
      </PixTabs>

      <div class="campaign-header-tabs__export-button hide-on-mobile">
        <PixButton @variant="primary" @triggerAction={{this.exportData}}>
          {{t "pages.campaign.actions.export-results"}}
        </PixButton>
      </div>
    </div>
  </template>
}
