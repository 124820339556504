import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ListController extends Controller {
  @service router;
  @service store;
  @service currentUser;
  @service notifications;
  @service intl;
  @tracked search = null;
  @tracked studentNumber = null;
  @tracked groups = [];
  @tracked certificability = [];
  @tracked pageNumber = null;
  @tracked pageSize = 50;
  @tracked participationCountOrder = null;
  @tracked lastnameSort = 'asc';

  get hasComputeOrganizationLearnerCertificabilityEnabled() {
    return this.currentUser.prescriber.computeOrganizationLearnerCertificability;
  }

  @action
  goToLearnerPage(learner) {
    this.router.transitionTo('authenticated.sup-organization-participants.sup-organization-participant', learner.id);
  }

  @action
  sortByParticipationCount() {
    if (!this.participationCountOrder) this.participationCountOrder = 'asc';
    else this.participationCountOrder = this.participationCountOrder === 'asc' ? 'desc' : 'asc';

    this.pageNumber = null;
    this.lastnameSort = null;
  }

  @action
  sortByLastname() {
    if (!this.lastnameSort) this.lastnameSort = 'asc';
    else this.lastnameSort = this.lastnameSort === 'asc' ? 'desc' : 'asc';

    this.participationCountOrder = null;
    this.pageNumber = null;
  }

  @action
  triggerFiltering(fieldName, value) {
    this[fieldName] = value || undefined;
    this.pageNumber = null;
  }

  @action
  onResetFilter() {
    this.search = null;
    this.studentNumber = null;
    this.groups = [];
    this.certificability = [];
    this.pageNumber = null;
  }

  @action
  async deleteStudents(listLearners) {
    try {
      await this.store.adapterFor('sup-organization-participant').deleteParticipants(
        this.currentUser.organization.id,
        listLearners.map(({ id }) => id),
      );

      this.notifications.sendSuccess(
        this.intl.t('pages.sup-organization-participants.action-bar.success-message', {
          count: listLearners.length,
          firstname: listLearners[0].firstName,
          lastname: listLearners[0].lastName,
        }),
      );

      this.send('refreshModel');
    } catch {
      this.notifications.sendError(
        this.intl.t('pages.sup-organization-participants.action-bar.error-message', {
          count: listLearners.length,
          firstname: listLearners[0].firstName,
          lastname: listLearners[0].lastName,
        }),
      );
    }
  }
}
