import Route from '@ember/routing/route';
import { service } from '@ember/service';
import get from 'lodash/get';

export default class TermsOfServiceRoute extends Route {
  @service currentUser;
  @service router;
  @service session;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, 'login');

    if (transition.isAborted) {
      return;
    }

    const pixOrgaTermsOfServiceStatus = get(this.currentUser, 'prescriber.pixOrgaTermsOfServiceStatus');
    if (pixOrgaTermsOfServiceStatus === 'accepted') {
      return this.router.replaceWith('');
    }
  }

  model() {
    return {
      legalDocumentStatus: this.currentUser.prescriber.pixOrgaTermsOfServiceStatus,
      legalDocumentPath: this.currentUser.prescriber.pixOrgaTermsOfServiceDocumentPath,
    };
  }
}
