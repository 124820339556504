import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import PixModal from '@1024pix/pix-ui/components/pix-modal';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import CopyButton from 'ember-cli-clipboard/components/copy-button';
import isClipboardSupported from 'ember-cli-clipboard/helpers/is-clipboard-supported';
import { t } from 'ember-intl';
import get from 'lodash/get';

export default class ManageAuthenticationMethodModal extends Component {
  @service store;
  @service notifications;
  @service intl;

  @tracked isUniquePasswordVisible = false;

  @tracked generatedPassword = null;

  @tracked tooltipTextUsername = this._t('section.username.copy');
  @tracked tooltipTextEmail = this._t('section.email.copy');
  @tracked tooltipTextGeneratedPassword = this._t('section.password.copy');

  defaultErrorMessage = this._t('error.default');

  @action
  clipboardSuccessUsername() {
    this.tooltipTextUsername = this._t('copied');
  }

  @action
  clipboardSuccessEmail() {
    this.tooltipTextEmail = this._t('copied');
  }

  @action
  clipboardSuccessGeneratedPassword() {
    this.tooltipTextGeneratedPassword = this._t('copied');
  }

  @action
  clipboardOutUsername() {
    this.tooltipTextUsername = this._t('section.username.copy');
  }

  @action
  clipboardOutEmail() {
    this.tooltipTextEmail = this._t('section.email.copy');
  }

  @action
  clipboardOutGeneratedPassword() {
    this.tooltipTextGeneratedPassword = this._t('section.password.copy');
  }

  @action
  async resetPassword(event) {
    event.preventDefault();
    const dependentUser = this.store.createRecord('dependent-user', {
      organizationId: this.args.organizationId,
      organizationLearnerId: this.args.student.id,
    });

    try {
      await dependentUser.save();
      this.generatedPassword = dependentUser.generatedPassword;
      this.isUniquePasswordVisible = !this.isUniquePasswordVisible;
    } catch {
      this.notifications.sendError(this._t('error.unexpected'));
    }
  }

  @action
  async generateUsernameWithTemporaryPassword(event) {
    event.preventDefault();
    const dependentUser = this.store.createRecord('dependent-user', {
      organizationId: this.args.organizationId,
      organizationLearnerId: this.args.student.id,
    });

    try {
      await dependentUser.save({ adapterOptions: { generateUsernameAndTemporaryPassword: true } });
      this.args.student.username = dependentUser.username;

      if (!this.args.student.email) {
        this.generatedPassword = dependentUser.generatedPassword;
        this.isUniquePasswordVisible = !this.isUniquePasswordVisible;
      }
    } catch (response) {
      const errorDetail = get(response, 'errors[0].detail', this.defaultErrorMessage);
      this.notifications.sendError(errorDetail);
    }
  }

  @action
  closeModal() {
    this.isUniquePasswordVisible = false;
    this.args.onClose();
  }

  _t(key) {
    return this.intl.t(`pages.sco-organization-participants.manage-authentication-method-modal.${key}`);
  }

  <template>
    <PixModal
      @title="{{t 'pages.sco-organization-participants.manage-authentication-method-modal.title'}}"
      @showModal={{@display}}
      @onCloseButtonClick={{this.closeModal}}
    >
      <:content>
        <form class="manage-authentication-window">
          <h2 class="manage-authentication-window__title">
            <PixIcon @name="link" />
            {{t "pages.sco-organization-participants.manage-authentication-method-modal.authentication-methods"}}
          </h2>

          <div>
            {{#if @student.isAuthenticatedFromGar}}
              <div class="manage-authentication-window__box">
                <div class="manage-authentication-window__subTitle manage-authentication-window__subTitle--mediacentre">
                  <span>
                    {{t
                      "pages.sco-organization-participants.manage-authentication-method-modal.section.mediacentre.label"
                    }}
                  </span>
                  <PixIcon @name="checkCircle" class="green-icon" />
                </div>
              </div>
            {{/if}}

            {{#if @student.hasEmail}}
              <div class="manage-authentication-window__box">
                <div class="manage-authentication-window__subTitle">
                  <h3>
                    {{t "pages.sco-organization-participants.manage-authentication-method-modal.section.email.label"}}
                  </h3>
                  <PixIcon @name="checkCircle" class="green-icon" />
                </div>
                <div class="input-container">
                  <div class="manage-authentication-window__clipboard">
                    <PixInput
                      @id="email"
                      name="email"
                      @value={{@student.email}}
                      disabled={{true}}
                      @screenReaderOnly={{true}}
                    >
                      <:label>{{t
                          "pages.sco-organization-participants.manage-authentication-method-modal.section.email.label"
                        }}</:label>
                    </PixInput>
                    {{#if (isClipboardSupported)}}
                      <PixTooltip @id="copy-email-tooltip" @position="top" @isInline={{true}}>
                        <:triggerElement>
                          <CopyButton
                            @text={{@student.email}}
                            @onSuccess={{this.clipboardSuccessEmail}}
                            {{on "mouseout" this.clipboardOutEmail}}
                            aria-label="{{t
                              'pages.sco-organization-participants.manage-authentication-method-modal.section.email.copy'
                            }}"
                            aria-describedby="copy-email-tooltip"
                            class="pix-icon-button pix-icon-button--small pix-icon-button--dark-grey"
                          >
                            <PixIcon @name="copy" />
                          </CopyButton>
                        </:triggerElement>
                        <:tooltip>
                          {{this.tooltipTextEmail}}
                        </:tooltip>
                      </PixTooltip>
                    {{/if}}
                  </div>
                </div>
              </div>
            {{/if}}

            <div class="manage-authentication-window__box">
              {{#if @student.displayAddUsernameAuthentication}}
                <div class="manage-authentication-window__subTitle">
                  <h3>
                    {{t
                      "pages.sco-organization-participants.manage-authentication-method-modal.section.add-username.label"
                    }}
                  </h3>
                  <PixIcon @name="checkCircle" class="grey-icon" />
                </div>
                <PixButton @triggerAction={{this.generateUsernameWithTemporaryPassword}}>
                  {{t
                    "pages.sco-organization-participants.manage-authentication-method-modal.section.add-username.button"
                  }}
                </PixButton>
              {{else}}
                {{#if @student.hasUsername}}
                  <div class="manage-authentication-window__subTitle">
                    <h3>
                      {{t
                        "pages.sco-organization-participants.manage-authentication-method-modal.section.username.label"
                      }}
                    </h3>
                    <PixIcon @name="checkCircle" class="green-icon" />
                  </div>
                  <div class="input-container">
                    <div class="manage-authentication-window__clipboard">
                      <PixInput
                        @id="username"
                        @value={{@student.username}}
                        disabled={{true}}
                        @screenReaderOnly={{true}}
                      >
                        <:label>{{t
                            "pages.sco-organization-participants.manage-authentication-method-modal.section.username.label"
                          }}</:label>
                      </PixInput>
                      {{#if (isClipboardSupported)}}
                        <PixTooltip @id="copy-username-tooltip" @position="top" @isInline={{true}}>
                          <:triggerElement>
                            <CopyButton
                              @text={{@student.username}}
                              @onSuccess={{this.clipboardSuccessUsername}}
                              {{on "mouseout" this.clipboardOutUsername}}
                              aria-label={{t
                                "pages.sco-organization-participants.manage-authentication-method-modal.section.username.copy"
                              }}
                              aria-describedby="copy-username-tooltip"
                              class="pix-icon-button pix-icon-button--small pix-icon-button--dark-grey"
                            >
                              <PixIcon @name="copy" />
                            </CopyButton>
                          </:triggerElement>
                          <:tooltip>
                            {{this.tooltipTextUsername}}
                          </:tooltip>
                        </PixTooltip>
                      {{/if}}
                    </div>
                  </div>
                {{/if}}
              {{/if}}
            </div>
          </div>

          {{#unless @student.isAuthenticatedWithGarOnly}}
            <div class="manage-authentication-window__footer">
              {{#if this.isUniquePasswordVisible}}
                <div>
                  <div class="input-container">
                    <div class="manage-authentication-window__clipboard">
                      <PixInput @id="generated-password" @value={{this.generatedPassword}} disabled={{true}}>
                        <:label>{{t
                            "pages.sco-organization-participants.manage-authentication-method-modal.section.password.label"
                          }}</:label>
                      </PixInput>
                      {{#if (isClipboardSupported)}}
                        <PixTooltip @id="copy-password-tooltip" @position="bottom-left" @isInline={{true}}>
                          <:triggerElement>
                            <CopyButton
                              @text={{this.generatedPassword}}
                              @onSuccess={{this.clipboardSuccessGeneratedPassword}}
                              {{on "mouseout" this.clipboardOutGeneratedPassword}}
                              aria-label={{t
                                "pages.sco-organization-participants.manage-authentication-method-modal.section.password.copy"
                              }}
                              aria-describedby="copy-password-tooltip"
                              class="pix-icon-button pix-icon-button--small manage-authentication-window__clipboard__copy-password-button"
                            >
                              <PixIcon @name="copy" class="fa-inverse" />
                            </CopyButton>
                          </:triggerElement>
                          <:tooltip>
                            {{this.tooltipTextGeneratedPassword}}
                          </:tooltip>
                        </PixTooltip>
                      {{/if}}
                    </div>
                  </div>

                  <ol class="manage-authentication-window__informations">
                    <li>
                      {{t
                        "pages.sco-organization-participants.manage-authentication-method-modal.section.password.warning-1"
                      }}
                    </li>
                    <li>
                      {{t
                        "pages.sco-organization-participants.manage-authentication-method-modal.section.password.warning-2"
                      }}
                    </li>
                    <li>
                      {{t
                        "pages.sco-organization-participants.manage-authentication-method-modal.section.password.warning-3"
                      }}
                    </li>
                  </ol>
                </div>
              {{else}}
                <div>
                  <PixButton id="generate-password" @triggerAction={{this.resetPassword}}>
                    {{t
                      "pages.sco-organization-participants.manage-authentication-method-modal.section.reset-password.button"
                    }}
                  </PixButton>

                  <div class="manage-authentication-window__warning">
                    <PixIcon @name="warning" @plainIcon={{true}} class="icon--warning" />
                    <span>{{t
                        "pages.sco-organization-participants.manage-authentication-method-modal.section.reset-password.warning"
                      }}</span>
                  </div>
                </div>
              {{/if}}
            </div>
          {{/unless}}
        </form>
      </:content>
    </PixModal>
  </template>
}
